<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b>Approval Customer Address</b>
    </span>
    <vs-tabs>
      <vs-tab label="Address">
        <div class="vx-row mb-6">
          <div class="vx-col w-1/2">
            <vs-input class="w-full readonly" label="Code Customer" v-model="data.customerCode" name="Code" readonly/>
          </div>
          <div class="vx-col w-1/2">
            <vs-input class="w-full readonly" label="Name Customer" v-model="data.customerName" name="Name" readonly/>
          </div>
        </div>
        <div class="tab-text">
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Code" v-model="data.code" name="Code" readonly/>
            </div>
            <div class="vx-col w-1/2">
              <vs-input class="w-full readonly" label="Code NOO" v-model="data.nooCode" name="Code" readonly/>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Status</label>
              <vs-checkbox v-model="data.status" vs-value="Billing Address" disabled
                >Billing Address</vs-checkbox
              >
              <vs-checkbox v-model="data.status" vs-value="Shipping Address" disabled
                >Shipping Address</vs-checkbox
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Territory</label>
              <multiselect
                v-model="territory.selected"
                :options="territory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
                disabled
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('TerritoryCustomer')"
                >{{ errors.first("TerritoryCustomer") }}</span
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label">Sales</label>
              <multiselect
                v-model="salesPersonal.selected"
                :options="salesPersonal.options"
                placeholder="Type to search"
                track-by="Name"
                label="Name"
                :max-height="125"
                disabled
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.Name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.Name }}</span>
                  </div>
                </template>
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('SalesCustomer')"
                >{{ errors.first("SalesCustomer") }}</span
              >
            </div>
          </div>

          <address-component
            :data="data.address"
            @data="setDataAddress"
          ></address-component>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
              class="w-full"
              label="RT / RW (Format 000/000)"
              v-model="data.rtRw"
              @keypress="isNumber($event)"
              name="RT/RW"
              readonly
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('RT/RW')"
              >{{ errors.first("RT/RW") }}</span
            >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                label="Street No"
                v-model="data.streetNo"
                readonly
              />
            </div>
          </div>
        </div>
        
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Collector</label>
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="data.isTaxCollector"
              disabled
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Exempt</label>
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="data.isTaxExempt"
              disabled
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Tax Type</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="taxType.selected"
                  :options="taxType.options"
                  placeholder="Type to search"
                  track-by="name"
                  label="taxType"
                  :max-height="125"
                  @input="handleTaxTypeInput"
                  disabled
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </div>
                  </template>
                </multiselect>
                <span class="text-danger text-sm" v-show="errors.has('taxType')">{{errors.first("taxType")}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Add Info</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="addInfo.selected"
                  :options="addInfo.options"
                  placeholder="Type to search"
                  track-by="code"
                  label="addInfo"
                  :max-height="125"
                  :custom-label="codeWithDescription"
                  disabled
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.description }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.description }}</span
                      >
                    </div>
                  </template>
                </multiselect>
                <!-- <span class="text-danger text-sm" v-show="errors.has('addInfo')">{{errors.first("addInfo")}}</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Facility Code</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="facilityCode.selected"
                  :options="facilityCode.options"
                  placeholder="Type to search"
                  track-by="code"
                  label="facilityCode"
                  :max-height="125"
                  :custom-label="codeWithDescription"
                  disabled
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.description }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.description }}</span
                      >
                    </div>
                  </template>
                </multiselect>
                <!-- <span class="text-danger text-sm" v-show="errors.has('facilityCode')">{{errors.first("facilityCode")}}</span> -->
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Status Block</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="StatusBlock"
                  :options="StatusBlockOption"
                  placeholder="Type to search"
                  track-by="code"
                  label="code"
                  :max-height="125"
                  disabled
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label class="vs-input--label">Delivery Type*</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="deliveryType.selected"
                  :options="deliveryType.options"
                  placeholder="Type to search"
                  track-by="name"
                  label="name"
                  :max-height="125"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{
                        props.option.name
                      }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.name
                      }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('DeliveryType')"
              >{{ errors.first("DeliveryType") }}</span
            >
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <label class="vs-input--label">Notes</label>
            <vs-textarea class="w-full" v-model="data.notes" readonly/>
          </div>
        </div>
        <vs-divider style="width: 100%; margin-left: 0%">History Notes</vs-divider>
        <vs-table
          stripe
          border
          description
          :sst="true"
          :data="data.historyNotes"
        >
          <template slot="thead">
            <vs-th>Time</vs-th>
            <vs-th>User</vs-th>
            <vs-th>Notes</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">{{ formatDateTime(tr.CreatedAt) }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ tr.personal_name }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ tr.notes }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>
      <vs-tab label="Map">
        <div class="tab-text">
          <map-component :data="data.map" @data="setDataMap"></map-component>
        </div>
      </vs-tab>
      <vs-tab label="Contact">
        <div class="tab-text">
          <contact-component
            :data="data.contact"
            @data="setDataContact"
          ></contact-component>
        </div>
      </vs-tab>
      <vs-tab label="Upload">
        <vs-divider style="width: 100%; margin-left: 0%">Outlet Image</vs-divider>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Outlet Image</span>
          </div>
          <div class="vx-col w-full">
            <input
              name="file_outlet"
              class="w-full inputx"
              type="file"
              ref="file_outlet"
              accept=".jpg, .jpeg, .png"
              @change="onImageChange($event, 'outlet')"
              disabled
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <img style="max-height: 170px; max-width: auto" :src="data.urlOutlet" />
          </div>
        </div>
      </vs-tab>
    </vs-tabs>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          v-if="showButtonApproveReject"
          class="ml-4 mt-2"
          color="primary"
          v-on:click="handleApproval('approve')"
          >Approve</vs-button
        >
        <vs-button
          v-if="showButtonApproveReject"
          class="ml-4 mt-2"
          color="warning"
          v-on:click="handleApproval('reject')"
          >Reject</vs-button
        >
        <vs-button
          v-if="showButtonApproveReject"
          class="ml-4 mt-2"
          color="success"
          v-on:click="handleInquiry()"
          >Inquiry</vs-button
        >
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
    <vs-prompt
      @accept="onAcceptModalReject"
      :active.sync="modalReject.activeModalReject"
      title="Confirm"
      accept-text="Save"
    >
      <div class="con-exemple-prompt">
        <div class="">
          <p>Reason</p><br>
        </div>
        <div>
          <multiselect
            class="selectExample"
            v-model="reason.selected"
            :options="reason.options"
            label="reason"
            placeholder="type to search"
          />
        </div>
        <div v-if="data.showFreeText">
          <vs-input
            class="w-full"
            label="Reason"
            v-model="data.reasonReject"
          />
        </div>
      </div>
    </vs-prompt>
    <vs-prompt
      @accept="onAcceptModalInquiry"
      :active.sync="activeModalInquiry"
      title="Confirm Inquiry"
      accept-text="Accept"
      color="success"
    > Are you sure ?
    </vs-prompt>
  </div>
</template>
<script>
import ContactComponent from "./form-component/Contact";
import MapComponent from "../../master/customer/form-component/Map.vue";
import AddressComponent from "@/components/master/Address";
import moment from "moment";

export default {
  components: {
    ContactComponent,
    MapComponent,
    AddressComponent,
  },
  props: {
    customerAddressId: {
      type: Number,
    },
    showButtonApproveReject: {
      type: Boolean,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/oms/v1/sfa-master/customer-address",
        activeModalInquiry : false,
        data: {
          reasonReject: "",
          historyNotes: [],
          notes: "",
          rtRw: "",
          street: "",
          streetNo: "",
          customerId: null,
          status: [],
          code: "",
          isTaxCollector: true,
          isTaxExempt: true,
          deliveryTypeId: null,
          img_outlet: null,
          urlOutlet: "",
          address: {
            countryName: "",
            countryID: 0,
            provinceName: "",
            provinceID: 0,
            cityName: "",
            cityID: 0,
            districtName: "",
            districtID: 0,
            subdistrictName: "",
            subdistrictID: 0,
            address: "",
            postalCode: "",
          },
          map: {
            latitude: "",
            longitude: "",
          },
          contact: {
            name: "",
            mobile: "",
            phone: "",
            email: "",
            fax: "",
          },
          showfreeText: false,
        },
        modalReject: {
          activeModalReject: false,
        },
        territory: {
          options: [],
          selected: null,
        },
        salesPersonal: {
          selected: null,
          options: [],
        },
        reason: {
          selected: null,
          options: [],
        },
        taxType: {
          selected: null,
          options: [],
        },
        addInfo: {
          selected: null,
          options: [],
        },
        facilityCode: {
          selected: null,
          options: [],
        },
        StatusBlock: null,
        StatusBlockOption: [
          { id: 0, code: "0 - Un-Block" },
          { id: 1, code: "1 - Order Block" },
          { id: 2, code: "2 - Return Block" },
          { id: 3, code: "3 - All Block" }
        ],
        deliveryType: {
          selected: null,
          options: [],
        },
      };
    },
    onImageChange(e, type) {
      console.log("on image change")
      const file = e.target.files[0];
      console.log(file)
      if (type == "outlet") {
        this.data.img_outlet = file;
        this.data.urlOutlet = URL.createObjectURL(file);
      }
    },
    setUrl(type, url) {
      if(type == "outlet"){
        this.data.urlOutlet = url
      }
    },
    codeWithDescription ({ description, code }) {
      if (description != undefined) {
        return `${code} - ${description}`
      }
      return `${code}`
    },
    formatDateTime(s){
      const m = moment.parseZone(s);
      return m.format('YYYY-MM-DD HH:mm:ss')
    },
    onAcceptModalInquiry(){
      this.$vs.loading();
      this.$http
      .post(this.baseUrl + "-inquiry/" + this.customerAddressId, {
        notes: this.data.notes
      })
      .then((resp) => {
        if (resp.code == 200) {
          this.notify("success", "Success", "Customer Updated")
          this.handleClose();
        } else {
          this.notify("danger", "Error", resp.message)
        }

        this.$vs.loading.close();
      })
      .catch((error) => {
        this.$vs.loading.close();
        console.log(error);
      });
    },
    handleSubmit(status_approval) {
      this.$validator.validateAll().then((result) => {
        if (!this.territory.selected) {
          this.errors.add({
            field: "TerritoryCustomer",
            msg: "The territory field is required",
          });
          result = false;
        }
        if (!this.salesPersonal.selected) {
          this.errors.add({
            field: "SalesCustomer",
            msg: "The Sales field is required",
          });
          result = false;
        }
        if (this.data.rtRw.length != 7 || this.data.rtRw.indexOf("/") != 3 || (this.data.rtRw.split("/").length - 1) > 1) {
          this.errors.add({
            field: "RT/RW",
            msg: "The RT / RW not valid format.",
          });
          result = false;
        }
        
        if (this.data.contact.mobile.length > 13) {
          this.data.contact.showWarningMobile = true
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field may not be greater than 13 characters.",
          });
          result = false;
        }
        if (this.data.contact.mobile.length < 10) {
          this.data.contact.showWarningMobile = true
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field at least 10 characters.",
          });
          result = false;
        }
        if (this.data.contact.phone.length > 13) {
          this.data.contact.showWarningPhone = true
          this.errors.add({
            field: "Phone",
            msg: "The Phone field may not be greater than 13 characters.",
          });
          result = false;
        }
        if (this.data.contact.phone.length < 10) {
          this.data.contact.showWarningPhone = true
          this.errors.add({
            field: "Phone",
            msg: "The Phone field at least 10 characters.",
          });
          result = false;
        }
        if (this.taxType.selected == null) {
          this.errors.add({
            field: "taxType",
            msg: "The Tax Type is required",
          });
          result = false;
        }
        if (!this.data.deliveryTypeId) {
          this.errors.add({
            field: "DeliveryType",
            msg: "Delivery Type field is required",
          });
          result = false;
          console.log('DeliveryType')
        }
        if (result) {
          this.$vs.loading();
          if (this.customerAddressId) {
            // this.uploadImageAndPostData(0);
            this.updateStatusApproval(status_approval)
            // this.putData(status_approval);
          }
        }
      });
    },
    paramData() {
      let is_tax_collector = "0";
      if (this.data.isTaxCollector) {
        is_tax_collector = "1";
      }
      let is_tax_exempt = "0";
      if (this.data.isTaxExempt) {
        is_tax_exempt = "1";
      }

      return {
        notes: this.data.notes,
        customer_id: this.data.customerId,
        code: this.data.code,
        territory_id: this.territory.selected.id ? this.territory.selected.id : this.territory.selected.ID,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        status: JSON.stringify(this.data.status),

        latitude: this.data.map.latitude,
        longitude: this.data.map.longitude,

        contact_name: this.data.contact.name,
        contact_mobile: this.data.contact.mobile,
        phone: this.data.contact.phone,
        email: this.data.contact.email,
        fax: this.data.contact.fax,

        sales_personal_id: this.salesPersonal.selected.ID,
        is_tax_collector: is_tax_collector,
        is_tax_exempt: is_tax_exempt,
        tax_type_id: this.taxType.selected ? (this.taxType.selected ? this.taxType.selected.ID : this.taxType.selected.id) : 0,
        add_info_ref_id: this.addInfo.selected ? (this.addInfo.selected ? this.addInfo.selected.ID : this.addInfo.selected.id) : 0,
        add_info_ref_code: this.addInfo.selected ? (this.addInfo.selected ? this.addInfo.selected.code : this.addInfo.selected.code) : "",
        facility_ref_id: this.facilityCode.selected ? (this.facilityCode.selected ? this.facilityCode.selected.ID : this.facilityCode.selected.id) : 0,
        facility_ref_code: this.facilityCode.selected ? (this.facilityCode.selected ? this.facilityCode.selected.code : this.facilityCode.selected.code) : "",
        shiping_block: this.StatusBlock.id,
        delivery_type_id: this.data.deliveryTypeId,
      };
    },
    putData(status_approval) {
      this.$http
        .put(
          "/api/oms/v1/sfa-master/customer-address-sfa/" + this.customerAddressId,
          this.paramData()
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.updateStatusApproval(status_approval)
          } else {
            this.notify("danger", "Error", resp.message)
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    onAcceptModalReject(){
      if(this.reason.selected == null) {
        this.modalReject.activeModalReject = true
        this.notify("danger", "Error", "Reason is required")
      } else {
        this.updateStatusApproval("3")
      }
    },
    handleInquiry(){
      if (this.data.notes == "") {
        this.notify("danger", "Error", "Please fill notes");
      } else {
        this.activeModalInquiry = !this.activeModalInquiry;
      }
    },
    handleApproval(status){
      if(status == 'approve'){
        var status_approval = '2'
        var message = "approve ?"
        var title = "Confirm Approve"

        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: title,
          text: 'Are you sure want to ' + message,
          accept: () => {
            this.$nextTick(() => {
              this.handleSubmit(status_approval);
            })
          },
        });
      } else {
        this.getDataReason();
        this.modalReject.activeModalReject = !this.modalReject.activeModalReject
      }
    },
    notify(color, title, message){
      this.$vs.notify({
        color: color,
        title: title,
        text: message,
        position: "top-right",
        iconPack: "feather",
        icon: "icon-x-circle",
      });
    },
    updateStatusApproval(status_approval) {
      this.$vs.loading();
      this.$http
      .post("/api/oms/v1/sfa-master/customer-address/change-status/" + this.customerAddressId, {
        status_approval: status_approval, // 2: approve, 3: reject
        reason_reject: this.data.reasonReject
      })
      .then((resp) => {
        if (resp.code == 200) {
          this.notify("success", "Success", "Success")
        } else {
          this.notify("danger", "Error", resp.message)
        }

        this.$vs.loading.close();
        this.handleClose();
      })
      .catch((error) => {
        this.$vs.loading.close();
        console.log(error);
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      console.log("qweqwewq ", this.showButtonApproveReject)
      this.$vs.loading();
      if (this.customerAddressId) {
        console.log("get data customer address")
        this.$http
          .get("/api/oms/v1/sfa-master/customer-address/" + this.customerAddressId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              console.log("RESP DATA SHIPTO",resp)
              // console.log("get data customer")
              // this.$http
              // .get("/api/oms/v1/sfa-master/customer/" + resp.data.customer_id)
              // .then((resp1) => {
              //   this.$vs.loading.close();
              //   if (resp1.code == 200) {
              //     console.log(resp1.data.code, resp1.data.name)
              //     this.data.customerCode = resp1.data.code;
              //     this.data.customerName = resp1.data.name;
              //   } else {
              //     this.notify("danger", "Error", resp1.message)
              //   }
              // })
              // .catch((error) => {
              //   this.$vs.loading.close();
              //   console.log(error);
              // });
              this.data.customerCode = resp.data.customer.code;
              this.data.customerName = resp.data.customer.name;
              this.data.customerId = resp.data.customer_id;
              this.data.code = resp.data.code;
              this.data.nooCode = resp.data.code_noo;
              this.territory.selected = resp.data.territory;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
              this.data.address.countryID = resp.data.country_id;
              this.data.address.provinceID = resp.data.province_id;
              this.data.address.cityID = resp.data.city_id;
              this.data.address.districtID = resp.data.district_id;
              this.data.address.subdistrictID = resp.data.sub_district_id;
              this.salesPersonal.selected = resp.data.sales;
              this.data.rtRw = resp.data.rt_rw;
              this.data.street = resp.data.street;
              this.data.streetNo = resp.data.street_no;
              // this.data.showButton = resp.data.status_approval == "1" ? true : false
              if (resp.data.is_tax_collector == "1") {
                this.data.isTaxCollector = true;
              } else {
                this.data.isTaxCollector = false;
              }
              if (resp.data.is_tax_exempt == "1") {
                this.data.isTaxExempt = true;
              } else {
                this.data.isTaxExempt = false;
              }

              if (resp.data.status) {
                this.data.status = JSON.parse(resp.data.status);
              }

              this.data.map.address = resp.data.map_address;
              this.data.map.latitude = resp.data.latitude;
              this.data.map.longitude = resp.data.longitude;

              this.data.contact.name = resp.data.contact_name;
              this.data.contact.mobile = resp.data.contact_mobile;
              this.data.contact.phone = resp.data.phone;
              this.data.contact.email = resp.data.email;
              this.data.contact.fax = resp.data.fax;

              if (resp.data.sales_personal_id) {
                this.salesPersonal.selected = {id: resp.data.sales_personal_id}
                this.setDataSales(resp.data.sales_personal_id)
              }
              this.data.historyNotes = resp.data.history_note
              this.StatusBlock = this.StatusBlockOption.find(
                option => option.id === resp.data.shiping_block
              );
              if (resp.data.tax_type_id) {
                this.setTaxTypeSelected(resp.data.tax_type_id)
                this.getDataAddInfo(resp.data.tax_type_id)
                this.getDataFacilityCode(resp.data.tax_type_id)
              }

              if (resp.data.add_info_ref_id) {
                this.setAddInfoSelected(resp.data.add_info_ref_id)
              }

              if (resp.data.facility_ref_id) {
                this.setFacilityCodeSelected(resp.data.facility_ref_id)
              }
              if (resp.data.delivery_type_id) {
                this.setDeliveryTypeSelected(resp.data.delivery_type_id);
              } else {
                this.deliveryType.selected
              }
              var setUrl = this.setUrl;
              resp.data.customer_address_image.forEach(function (item) {
                setUrl(item.type, item.photo_url);
              }, setUrl)
            } else {
              this.notify("danger", "Error", resp.message)
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getDataTerritories() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/setting-user/territory-master", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTerritory(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/setting-user/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.territory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDataSales(territoryID) {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/customer-sales/territory/" + territoryID)
        .then((resp) => {
          if (resp.status == "success") {
            this.salesPersonal.options = resp.data;
            console.log("get data sales ", resp.data)
            if (this.salesPersonal.selected != null) {
              let obj = resp.data.find(o => o.ID === this.salesPersonal.selected.id || o.id === this.salesPersonal.selected.ID);
              this.salesPersonal.selected = obj
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataReason() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/reason-active", {
          params: {
            order: "code",
            sort: "asc",
            reason_group: "NOO Reject",
            is_active: true
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.reason.options = resp.data.records;
            console.log(resp.data.records)
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setDataContact(val) {
      this.data.contact = val;
    },
    setDataMap(val) {
      this.data.map = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
    setDataSales(selectedSalesPersonalID) {
      if (this.salesPersonal.options.length > 0) {
        let obj = this.salesPersonal.options.find(o => o.ID === selectedSalesPersonalID || o.id === selectedSalesPersonalID);
        this.salesPersonal.selected = obj
      }
    },
    getDataTaxType() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/tax-type", {
          params: {
            order: "code",
            sort: "asc",
            is_customer: true,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.taxType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataAddInfo(tax_type_id) {
      console.log("TAX TYPE ID",tax_type_id)
      this.addInfo.options = []
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/add-info", {
          params: {
            order: "code",
            sort: "asc",
            tax_type_id: tax_type_id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            console.log("RESP ADD INFO",resp)
            this.addInfo.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataFacilityCode(tax_type_id) {
      this.facilityCode.options = []
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/facility-ref", {
          params: {
            order: "code",
            sort: "asc",
            tax_type_id: tax_type_id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            console.log("RESP FACILITIY",resp)
            this.facilityCode.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          console.log("DATA TAX TYPE",resp.data)
          this.taxType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setAddInfoSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/add-info/" + id).then((resp) => {
        if (resp.status == "success") {
          this.addInfo.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setFacilityCodeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/facility-ref/" + id).then((resp) => {
        if (resp.status == "success") {
          this.facilityCode.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setAddInfoTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/add-info/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          console.log("Selected Add Info:", resp.data);
          this.addInfo.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setFacilityCodeTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/facility-ref/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.facilityCode.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleTaxTypeInput(selectedOption) {
      console.log("Selected Tax Type:", selectedOption);
      // Lakukan sesuatu dengan selectedOption
      if (selectedOption && selectedOption.ID != 0) {
        this.getDataAddInfo(selectedOption.ID)
        this.getDataFacilityCode(selectedOption.ID)
        this.setAddInfoTaxTypeSelected(selectedOption.ID)
        this.setFacilityCodeTaxTypeSelected(selectedOption.ID)
      }
    },
    getAllDataDeliveryType() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/delivery-type", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.deliveryType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setDeliveryTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/delivery-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.deliveryType.selected = resp.data[0];
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    uploadImageAndPostData(){
      var file_outlet = this.data.img_outlet;
      console.log("masuk kesini outlet",file_outlet,this.data.urlOutlet)
      if (file_outlet == null && this.data.urlOutlet == "") {
        this.notify("danger", "Form Validation", "File Outlet Image is required")
      } else if ((file_outlet != null && this.data.urlOutlet != "")) {
        console.log("masuk kesini 6")
        var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        var status_image = true

        if (file_outlet != null && !validImageTypes.includes(file_outlet.type)) {
          status_image = false
          this.notify("danger", "Form Validation", "File OUTLET must be an image")
        }

        if (status_image){
          this.formData = new FormData();
          if(file_outlet != null){
            this.formData.append("file_outlet", file_outlet);
          }
          //new
          this.$vs.loading();
          let customerAddressId = this.customerAddressId ? this.customerAddressId : 0

          console.log("==== CUSTOMER ADDRESS ID ====",customerAddressId)
          if (customerAddressId != 0){
            console.log("==== MASUK SINI ====")
            this.$http
            .post("api/oms/v1/sfa-master/customer-address-sfa-photo/"+customerAddressId, this.formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.status == "success") {
                // this.handleClose();
                this.putData();
              } else {
                this.notify("danger", "Error", resp.message)
              }
            });
          } else {
            this.$vs.loading();
            this.$http
              .post("/api/oms/v1/sfa-master/customer-address-sfa", this.paramData())
              .then((resp) => {
                if (resp.code == 200) {
                  this.$http
                  .post("api/oms/v1/sfa-master/customer-address-sfa-photo/"+resp.data.ID, this.formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((resp2) => {
                    this.handleClose();
                    this.$vs.loading.close();
                    if (resp2.status == "success") {
                      this.notify("success", "Success", "New Customer Address Created")
                    } else {
                      this.notify("danger", "Error", resp2.message)
                    }
                  });
                } else {
                  this.notify("danger", "Error", resp.message)
                  this.$vs.loading.close();
                }
              })
              .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
              });
          }
        }
      }
      else { // jika tidak ada file yg di upload
        if (this.customerAddressId) {
          this.putData();
        } else {
          this.postData();
        }
      }
      console.log("masuk kesini 3")
    },
  },
  mounted() {
    this.getData();
    this.getDataTerritories();
    this.getDataTaxType();
    this.getAllDataDeliveryType();
  },
  computed: {},
  watch: {
    customerAddressId(v) {
      this.getDataTaxType();
      this.getAllDataDeliveryType();
      if (v) {
        this.getData();
      } else {
        this.getData();
        Object.assign(this.$data, this.initialState());
      }
    },
    "territory.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        // let id = val.id ? val.id : val.ID
        this.getDataSales(val.id ? val.id : val.ID)
      }
    },
    "reason.selected": function (val) {
      if (val.reason == "Others.") {
        this.data.showFreeText = true
        this.data.reasonReject = ""
      } else {
        this.data.reasonReject = val.reason
        this.data.showFreeText = false
      }
    },
    "deliveryType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.deliveryTypeId = val.id ? val.id : val.ID;
      } else {
        this.data.deliveryTypeId = null;
      }
    },
  },
};
</script>
<style >
.vs-dialog {
  max-width: 700px !important;
  height: auto;
}

.readonly > div > input {
  background-color: #ebebeb;
}
</style>